
export default {
  props: ['multiselect', 'extensions', 'sizeLimit', 'filePicker'],
  data: () => ({
    apiKey: process.env.DROPBOX_API_KEY,
    scriptLoaded: false,
    dropboxChooserIsSupported: false
  }),
  watch: {
    scriptLoaded (val) {
      if (val) {
        this.$emit('scriptLoaded')
      }
    }
  },
  mounted () {
    const element = document.getElementById('FileInputDropdown')
    if (!this.filePicker || element.matches(':hover')) {
      this.loadScript()
    }
  },
  methods: {
    loadScript () {
      if (window.Dropbox) {
        this.scriptLoaded = true
      } else {
        const dropBoxScript = document.createElement('script')
        dropBoxScript.onload = () => {
          this.scriptLoaded = true
          this.dropboxChooserIsSupported = window.Dropbox.isBrowserSupported()
          if (!this.dropboxChooserIsSupported) {
            console.warn('VueDropboxPicker: This browser is not supported')
          }
        }
        dropBoxScript.setAttribute('src', 'https://www.dropbox.com/static/api/2/dropins.js')
        dropBoxScript.setAttribute('id', 'dropboxjs')
        dropBoxScript.setAttribute('data-app-key', this.apiKey)
        document.head.appendChild(dropBoxScript)
      }
    },
    async openChooser () {
      await this.loadScript()
      const options = {
        multiselect: this.multiselect,
        folderselect: false,
        linkType: 'direct',
        success: (files) => {
          this.$emit('picked', files.map(file => ({ name: file.name, url: file.link, size: file.bytes, uploadMethod: 'dropbox' })))
        },
        cancel: () => {
          this.$emit('cancel')
        }
      }
      if (this.extensions && this.extensions.length) {
        options.extensions = this.extensions
      }
      if (this.sizeLimit) {
        options.sizeLimit = this.sizeLimit
      }
      const loaded = setInterval(
        () => {
          if (window.Dropbox) {
            window.Dropbox.choose(options)
            clearInterval(loaded)
          }
        }, 100
      )
    },
    async saveFile (url) {
      await this.loadScript()
      const options = {
        success: () => {
          this.$emit('progress', 100)
          this.$emit('saved')
        },
        progress: (progress) => {
          this.$emit('progress', progress * 100)
        },
        cancel: () => {
          this.$emit('cancel')
          // eslint-disable-next-line no-undef
          $nuxt.$store.commit('setAlert', {
            type: 'danger',
            sticky: true,
            msg: this.$t('dropbox_failed')
          })
        },
        error: () => {}
      }
      const loaded = setInterval(
        () => {
          if (window.Dropbox) {
            window.Dropbox.save(url, decodeURIComponent(url.split('/').pop()), options)
            clearInterval(loaded)
          }
        }, 100
      )
    },
    open () {
      this.openChooser()
    }
  }
}
